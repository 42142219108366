import React from 'react';
import SEO from '../components/core/meta/SEO';
import Heading from '../components/core/typography/Heading';
import { Container } from '../components/core/layout/Layout';
import 'twin.macro';
import ListItem from '../components/core/lists/ListItem';
import List from '../components/core/lists/List';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import PageHeader from '../components/views/PageHeader/PageHeader';

const CovidProtocolPage = () => {
  const covidData = useStaticQuery<any>(graphql`
    query Covid {
      covid: file(relativePath: { eq: "covid.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      covid2: file(relativePath: { eq: "covid2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title="Protocollo covid" url="/protocollo-sicurezza-covid19" />
      <PageHeader backgroundImage={covidData.covid2.childImageSharp.fluid}>
        <Container tw="py-16">
          <Heading variant="h1" tw="text-white mb-0">
            Prevenzione e sicurezza
          </Heading>
        </Container>
      </PageHeader>
      <Container tw="py-16">
        <div tw="mb-10">
          <Heading variant="h4" tw="mb-10">
            Linee Guida erogazione servizi di accompagnamento e affiancamento
            emergenza Covid | Fase 2
          </Heading>
          <Heading variant="h6">Informazioni principali</Heading>
          <p tw="text-paragraph">
            I coronavirus fanno parte di una vasta famiglia di virus che possono
            causare un raffreddore comune, una forma lieve simile all’influenza
            ma anche malattie più gravi come la sindrome respiratoria
            mediorientale (MERS) e la sindrome respiratoria acuta grave (SARS).
            Il virus responsabile dell’epidemia attuale è un nuovo ceppo di
            coronavirus – denominato SARS-CoV2 – mai identificato in precedenza
            nell’uomo. Il 7 ottobre Il Presidente del Consiglio ha emanato un
            Decreto-legge che proroga lo stato di emergenza al 31 gennaio 2021 e
            la continuità operativa del sistema di allerta COVID al 15 ottobre
            (in via di proroga).
          </p>
        </div>
        <div tw="mb-10">
          <Heading variant="h6">Trasmissione</Heading>
          <p tw="text-paragraph mb-4">
            I coronavirus umani si trasmettono da una persona infetta a un’altra
            attraverso:
          </p>
          <List>
            <ListItem>la saliva, tossendo e starnutendo</ListItem>
            <ListItem> contatti diretti personali</ListItem>
            <ListItem>
              le mani, ad esempio toccando con le mani contaminate (non ancora
              lavate) bocca, naso o occhi
            </ListItem>
            <ListItem>una contaminazione fecale (raramente)</ListItem>
          </List>
          <p tw="text-paragraph mb-4">
            Non esistono trattamenti specifici per le infezioni causate dai
            coronavirus e non sono disponibili, al momento, vaccini per
            proteggersi dal virus. Riguardo il nuovo coronavirus SARS-CoV-2, non
            esistono al momento terapie specifiche, vengono curati i sintomi
            della malattia (terapia di supporto) in modo da favorire la
            guarigione, ad esempio fornendo supporto respiratorio. Si ricorda
            che l’Agenzia italiana del farmaco (AIFA) fornisce sul proprio sito
            informazioni sui farmaci utilizzati al di fuori delle
            sperimentazioni cliniche. Puoi consultare la pagina dedicata sul
            sito AIFA. Ti consigliamo anche di controllare giornalmente lo
            scenario del virus, ci sono varie fonti. Consigliamo fra le altre,
            il sito de Il Sole 24 Ore , chiaro e di facile lettura.
          </p>
        </div>
        <div tw="mb-10">
          <Heading variant="h6">Prevenzione e sicurezza</Heading>
          <p tw="text-paragraph">
            Per svolgere OGNI servizio di accompagnamento e affiancamento , ti
            chiediamo di rispettare SEMPRE attentamente le linee guida che
            seguono. Non abbassare mai la guardia nel rispetto della tua salute,
            di quella delle persone o meno fragili che affianchi e della
            comunità in senso esteso.
          </p>
        </div>
        <div tw="mb-10">
          <Heading variant="h6">
            Usa sempre e correttamente la mascherina
          </Heading>
          <List>
            <ListItem>
              Utilizza sempre la mascherina durante l’erogazione dei servizi.
              L’ideale è avere in dotazione la tipologia fp2, ma il DPCM
              chiarisce che in comunità possono essere utilizzate mascherine
              monouso o mascherine lavabili, anche auto-prodotte, purché coprano
              mento e naso (anche naso!!!).
            </ListItem>
            <ListItem>
              Indossa la mascherina toccando solo gli elastici o i legacci e
              avendo cura di non toccare la parte interna.
            </ListItem>
            <ListItem>
              Cambia spesso la mascherina in uso, come da indicazioni del DPCM
            </ListItem>
            <ListItem>
              Non indossare più mascherine chirurgiche sovrapposte, non è utile.
            </ListItem>
            <ListItem>
              Tieni in dotazione mascherine monouso . Es. se ritieni di aver
              toccato con mani non pulite la mascherina, cambiala
              tempestivamente.
            </ListItem>
          </List>
        </div>
        <div tw="mb-10">
          <Heading variant="h6">Distanza di 1 metro</Heading>
          <List>
            <ListItem>
              Mantieni la distanza di 1 metro dall’utente ogni volta che ti è
              possibile. Sappiamo che non avere contatto nel nostro lavoro è
              molto difficile e spesso del tutto incompatibile con lo scopo del
              servizio: garantire l’aiuto dovuto.
            </ListItem>
            <ListItem>
              Arrivati a destinazione, tu e l’utente dovete sedere
              distanziandovi per quanto più possibile tra voi.
            </ListItem>
            <ListItem>
              Evita sempre abbracci, saluti con contatto e strette di mano.
            </ListItem>
            <ListItem>
              Riduci le comunicazioni verbali quando non siete distanziati.
            </ListItem>
            <ListItem>
              Minimo contatto durante lo svolgimento delle commissioni.
            </ListItem>
          </List>
        </div>
        <div tw="mb-10">
          <Heading variant="h6">Utilizzo dei guanti</Heading>
          <List>
            <ListItem>
              L’utilizzo dei guanti è consigliato tutte le volte che effettui un
              servizio , a maggior ragione se sei tu ad occuparti della
              movimentazione dell’utente.
            </ListItem>
            <ListItem>
              I guanti non sostituiscono la corretta igiene delle mani , che
              deve avvenire attraverso un lavaggio accurato e per 60 secondi.
            </ListItem>
            <ListItem>
              I guanti devono essere cambiati spesso, non sono lavabili e non
              devono mai entrare in contatto con bocca, naso e occhi.
            </ListItem>
            <ListItem>I guanti non possono essere riutilizzati.</ListItem>
          </List>
        </div>
        <div tw="mb-10">
          <Heading variant="h6">Detersione</Heading>
          <List>
            <ListItem>
              Lava spesso le mani con acqua e sapone o, in assenza, frizionale
              con un gel a base alcolica.
            </ListItem>
            <ListItem>
              Il lavaggio delle mani deve durare almeno 60 secondi.
            </ListItem>
            <ListItem>
              Il lavaggio delle mani deve coinvolgere accuratamente tutta la
              mano, incluse le dita, le punte, il pollice, il palmo della mano e
              la pelle fino ai polsi. Qui alleghiamo una chiara infografica che
              mostra come farlo bene.
            </ListItem>
            <ListItem>
              Sono sconsigliati in questo periodo bracciali, orologi e altri
              oggetti e gioielli ai polsi che intralcino la corretta detersione
            </ListItem>
            <ListItem>
              Dopo ogni servizio provvedi a igienizzare le mani.
            </ListItem>
            <ListItem>
              Non toccarti occhi, naso e bocca con le mani. Se non puoi
              evitarlo, lavati sempre le mani prima e dopo il contatto.
            </ListItem>
            <ListItem>
              Quando starnutisci copri bocca e naso con fazzoletti monouso e poi
              buttali via. Se non ne hai, usa la piega del gomito. Lava le mani
              appena possibile.
            </ListItem>
          </List>
        </div>
        <div tw="mb-10">
          <Heading variant="h6">Auto sempre igienizzata</Heading>
          <List>
            <ListItem>
              Prima e dopo ogni servizio utilizza prodotti igienizzanti per
              l’auto, ricordando di passare bene le parti maggiormente
              utilizzate: maniglie, cambio, cinture. I prodotti da utilizzare
              dovranno essere disinfettanti a base di cloro o alcol (no amuchina
              o candeggina che potrebbero danneggiare plastica e pelle).
            </ListItem>
            <ListItem>
              Hai servizi consecutivi? Aspetta almeno 20 minuti tra un servizio
              e l’altro, abbassa i finestrini e fai arieggiare . Igienizza
              l’abitacolo come da punto 1.
            </ListItem>
            <ListItem>
              Possono stare massimo due passeggeri in una macchina standard 5
              posti
            </ListItem>
            <ListItem>
              Gli utenti non possono sedersi nei posti anteriori ma sempre
              dietro
            </ListItem>
            <ListItem>
              Controlla e tieni puliti i filtri dell’aria condizionata
            </ListItem>
          </List>
        </div>
        <div tw="mb-10">
          <Heading variant="h6">Relazione sicura con l’utente</Heading>
          <List>
            <ListItem>
              Nella telefonata conoscitiva e di dettaglio che precede un
              servizio, ricorda agli utenti di dotarsi dei mezzi necessari per
              la prevenzione: mascherina, guanti – se necessari e se hanno
              bisogno del vostro supporto durante la movimentazione, prodotti
              igienizzanti.
            </ListItem>
            <ListItem>
              Ricorda ad ogni utente che lavorate nel rispetto delle norme . Di
              fronte a comportamenti scorretti da parte loro, sarete legittimati
              a segnalarlo ed eventualmente sospendere l’accompagnamento.
            </ListItem>
            <ListItem>
              Evita se ti è possibile contatti ravvicinati ma tieni la distanza
              di 1m.
            </ListItem>
            <ListItem>
              Non usare bottiglie e bicchieri toccati da altri e ricorda
              all’utente di non farlo a sua volta.
            </ListItem>
            <ListItem>
              Rassicura e chiarisci all’anziano quello che succede intorno a voi
              e quali sono le precauzioni da adottare. L’obiettivo è vivere in
              sicurezza e quindi acquistare libertà, non il contrario. E il
              servizio sarà piacevole comunque!
            </ListItem>
            <ListItem>
              Dai il tuo contributo ricordando all’utente di lavarsi o
              igienizzare le mani, soprattutto dopo aver toccato corrimano,
              maniglie, essere stato in ospedale, ecc.
            </ListItem>
            <ListItem>
              Presta attenzione che anche lui utilizzi correttamente la
              mascherina, coprendo bocca e naso e non togliendola mai.
            </ListItem>
            <ListItem>
              Mantieni concentrazione e attenzione costanti . Ricorda che molti
              utenti sono fragili: immunodepressi, malati oncologici, portatori
              di varie patologie e per questo devono essere tutelati al meglio.
              Spesso l’abitudine porta ad abbassare l’attenzione, tu non farlo
              mai!
            </ListItem>
          </List>
        </div>
        <div tw="mb-10">
          <Heading variant="h6">
            Impossibilità di erogazione del servizio
          </Heading>
          <p tw="text-paragraph mb-4">
            Un servizio potrà essere da voi interrotto se non verranno
            rispettate le norme inserite nel nuovo DPCM, in particolare:
          </p>
          <List tw="mb-4">
            <ListItem>
              se l’accompagnamento prevede un numero di persone non consentite
              per l’abitacolo dell’auto utilizzata per il servizio (max 2 per
              auto 5 porte).
            </ListItem>
            <ListItem>se l’utente non indossa la maschera P.V.R.</ListItem>
            <ListItem>
              se l’utente si rifiuta di attenersi alle disposizioni di cui al
              protocollo.
            </ListItem>
          </List>
          <p tw="text-paragraph mb-4">
            Il servizio potrà essere sospeso viceversa dall’utente, qualora
            riscontri scarso rispetto da parte vostra, operatori UGO, delle
            regole. L’utente potrà comunicarlo tramite una segnalazione al
            nostro Team. Inoltre questa informazione verrà rilevata in fase di
            feedback post servizio e attraverso verifiche telefoniche a
            campione.
          </p>
          <p tw="text-paragraph mb-4">
            Sarà possibile applicare una penale post-servizio qualora nel
            feedback verrà chiaramente esplicitata la mancanza da parte
            dell’UGO, di comportamenti consigliati e necessari in tutela e
            prevenzione del virus. Inoltre l’operatore potrà essere sospeso con
            effetto immediato.
          </p>
        </div>
        <div tw="mb-10">
          <Heading variant="h6">
            Ipotesi contagio o legami con persone infette
          </Heading>
          <p tw="text-paragraph mb-4">
            Se hai febbre, tosse o difficoltà respiratorie e sospetti di essere
            stato in stretto contatto con una persona affetta da Covid-19, resta
            in casa , non recarti al pronto soccorso o presso gli studi medici
            ma telefona al medico di famiglia o alla guardia medica, oppure
            chiama il numero verde regionale. Utilizza i numeri di emergenza
            112/118 solo in caso di peggioramento dei sintomi o difficoltà
            respiratoria. Puoi chiamare il numero gratuito di pubblica utilità
            1500 del ministero o il numero verde della Croce Rossa Italiana
            800.065.510 attivato per l’assistenza alle persone fragili. Gli
            operatori rispondono anche per informazioni sui comportamenti
            corretti da rispettare e l’iter da seguire in caso di contatto
            stretto con soggetti positivi. Numero verde della tua Regione:{' '}
            <a
              tw="text-primary-500 font-bold"
              href="http://www.salute.gov.it/portale/nuovocoronavirus/dettaglioContenutiNuovoCoronavirus.jsp?lingua=italiano&id=5364&area=nuovoCoronavirus&menu=aChiRivolgersi"
              target="_blank"
            >
              Numeri verdi regionali.
            </a>
          </p>
          <p tw="text-paragraph font-bold">
            Ricorda di avvisare tempestivamente il team UGO in modo da poter
            procedere sia con la gestione dei servizi a te assegnati ma
            soprattutto valutare insieme la rosa di persone da contattare e
            informare perché a loro volta venute in contatto con te.
          </p>
        </div>
        <div tw="mb-10">
          <Heading variant="h6">Altri consigli pratici</Heading>
          <List tw="mb-4">
            <ListItem>
              Se non l’hai ancora fatto ti consigliamo di sottoporti al tampone
              o al test sierologico quanto prima: si tratta di un controllo
              importante che tutti noi dovremmo fare periodicamente soprattutto
              se sappiamo di esporci a rischi
            </ListItem>
            <ListItem>
              La vaccinazione anti-influenzale è fortemente raccomandata , anche
              se non protegge da questo virus. Ma in caso di contagio l’aver
              fatto la vaccinazione renderebbe più facile e rapido l’isolamento
              di eventuali casi di Covid. Su questo tema si sta facendo ancora
              chiarezza su tanti aspetti, vi aggiorneremo non appena avremo
              notizie più certe.
            </ListItem>
            <ListItem>
              Segui la segnaletica proposta dalle strutture: ingressi e uscite
              sono differenziati e ci sono altre indicazioni volte alla
              sicurezza.
            </ListItem>
            <ListItem>
              Mantieniti informato sulla diffusione della pandemia, con i report
              attendibili disponibili sul{' '}
              <a
                tw="text-primary-500 font-bold"
                href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/situation-reports/"
                target="_blank"
              >
                sito dell’OMS
              </a>{' '}
              e sul{' '}
              <a
                tw="text-primary-500 font-bold"
                href="http://www.salute.gov.it/portale/nuovocoronavirus/homeNuovoCoronavirus.jsp"
                target="_blank"
              >
                sito del Ministero.
              </a>
            </ListItem>
            <ListItem>
              In possibilità, stampa i consigli pratici che trovi{' '}
              <a
                tw="text-primary-500 font-bold"
                href="http://www.salute.gov.it/portale/nuovocoronavirus/dettaglioOpuscoliNuovoCoronavirus.jsp?lingua=italiano&id=463"
                target="_blank"
              >
                qui
              </a>{' '}
              e tienili in auto.
            </ListItem>
            <ListItem>
              Fatti promotore e sensibilizza le persone con cui entri in
              contatto e ti relazioni verso l’applicazione delle buone pratiche
              di sicurezza e prevenzione.
            </ListItem>
            <ListItem>
              Ti consigliamo di scaricare l’App “Immuni” cliccando{' '}
              <a
                tw="text-primary-500 font-bold"
                href="https://www.immuni.italia.it/download.html"
                target="_blank"
              >
                qui
              </a>
              ..
            </ListItem>
          </List>
          <p tw="text-paragraph mb-4">
            Il servizio potrà essere sospeso viceversa dall’utente, qualora
            riscontri scarso rispetto da parte vostra, operatori UGO, delle
            regole. L’utente potrà comunicarlo tramite una segnalazione al
            nostro Team. Inoltre questa informazione verrà rilevata in fase di
            feedback post servizio e attraverso verifiche telefoniche a
            campione.
          </p>
          <p tw="text-paragraph mb-4">
            Sarà possibile applicare una penale post-servizio qualora nel
            feedback verrà chiaramente esplicitata la mancanza da parte
            dell’UGO, di comportamenti consigliati e necessari in tutela e
            prevenzione del virus. Inoltre l’operatore potrà essere sospeso con
            effetto immediato.
          </p>
        </div>
        <div tw="mb-10">
          <p tw="text-paragraph mb-5">******</p>
          <p tw="text-paragraph mb-5">
            Ricordiamo che in questo periodo di massima allerta chiederemo
            feedback precisi agli utenti riguardanti il rispetto delle buone
            norme igienico-sanitarie di prevenzione contenute in questo
            documento. Così come ci confronteremo con voi. Le norme riportate
            vogliono favorire sicurezza e buona salute degli utenti, di voi
            operatori e in senso più esteso della comunità tutta. Soltanto
            lavorando tutti con impegno, attenzione e sensibilità, potremo
            proteggerci e contenere i rischi.
          </p>
          <p tw="text-paragraph">
            Seguiranno aggiornamenti secondo quanto comunicato nei nuovi DPCM.
          </p>
          <p tw="text-paragraph">
            Grazie per la vostra collaborazione,
            <br />
            Team UGO
          </p>
        </div>
        <div tw="md:w-2/3 mb-10">
          <Image
            alt="virus protocollo"
            fluid={covidData.covid.childImageSharp.fluid}
          />
        </div>
      </Container>
    </>
  );
};

export default CovidProtocolPage;
