import BackgroundImage from 'gatsby-background-image';
import { FluidObject } from 'gatsby-image';
import React, { PropsWithChildren } from 'react';
import 'twin.macro';

interface PageHeaderProps {
  backgroundImage: FluidObject;
}

/**
 *  Subpage header wrapper with a background image
 */
const PageHeader = ({
  backgroundImage,
  children,
}: PropsWithChildren<PageHeaderProps>) => {
  return (
    <BackgroundImage fluid={backgroundImage}>
      <div tw="bg-black bg-opacity-60 py-16">{children}</div>
    </BackgroundImage>
  );
};

export default PageHeader;
